import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

import 'bootstrap';

const app = createApp(App);
app.use(router);
app.mount('#app');

// createApp(App).use(router).mount('#app')
